import { useState, useRef, SetStateAction } from "react";
import {
  OnChange,
  OptionalStateProps,
  useOptionalState,
} from "./use-optional-state";

function getValue(eventOrValue: any) {
  if (
    eventOrValue &&
    (eventOrValue instanceof Event || eventOrValue.nativeEvent instanceof Event)
  ) {
    return eventOrValue.target.value;
  }

  return eventOrValue;
}

function cloneValue<T>(value: T) {
  return (Array.isArray(value) ? [...value] : { ...value }) as T;
}

type NewValue<T> = SetStateAction<T>;

export function useFields<ValueType>(prop: OptionalStateProps<ValueType>) {
  const handlers = useRef<Partial<Record<keyof ValueType, OnChange<any>>>>({});

  const previousRenderErroredRef = useRef(false);
  const [isShowingErrors, setIsShowingErrors] = useState(false);

  const [value, onChange] = useOptionalState(prop);
  const [checkpoint, setCheckpoint] = useState<ValueType>(value);

  const touched = useRef<Partial<Record<keyof ValueType, true>>>({});

  function field<K extends keyof ValueType>(name: K) {
    type SubValue = ValueType[K];
    let handler: OnChange<SubValue> | undefined = handlers.current[name];
    if (!handler) {
      handler = (newValue: SetStateAction<SubValue>) => {
        return onChange((value) => {
          newValue = getValue(newValue);

          const clone: ValueType = cloneValue(value);

          const realValue: SubValue =
            // Typescript thinks newValue is not typeof 'function'
            // @ts-expect-error
            typeof newValue === "function" ? newValue(value[name]) : newValue;

          clone[name] = realValue;

          touched.current[name] = true;

          return clone;
        });
      };
      handlers.current[name] = handler;
    }

    return {
      onChange: handler!,
      value: value[name],
    };
  }

  function set<K extends keyof ValueType>(key: K, value: ValueType[K]) {
    field(key).onChange(value);
  }

  function setFields(fields: NewValue<Partial<ValueType>>) {
    onChange((existing) => {
      fields = typeof fields === "function" ? fields(existing) : fields;

      Object.keys(fields).forEach(
        (key) => (touched.current[key as keyof ValueType] = true)
      );

      const val = {
        ...existing,
        ...fields,
      };

      return val;
    });
  }

  function reset() {
    onChange(checkpoint);
    touched.current = {};
  }

  function updateCheckpoint(v?: ValueType) {
    onChange((value) => {
      const checkpoint = v === undefined ? value : v;
      setCheckpoint(checkpoint);
      return checkpoint;
    });
    touched.current = {};
  }

  function validate(name: keyof ValueType, bool: boolean) {
    if (bool) previousRenderErroredRef.current = true;
    if (!touched.current[name] && !isShowingErrors) return false;
    return bool;
  }

  function showErrors() {
    setIsShowingErrors(true);
    return previousRenderErroredRef.current;
  }

  const hasErrors = previousRenderErroredRef.current;
  previousRenderErroredRef.current = false;

  return {
    field,
    value,
    set,
    touched: touched.current,
    reset,
    checkpoint: updateCheckpoint,
    validate,
    hasErrors,
    showErrors,
    onChange: setFields,
    isChanged: checkpoint !== value,
  };
}
