import {
  useState,
  forwardRef,
  ReactNode,
  ButtonHTMLAttributes,
  MouseEvent,
} from "react";
import { useHistory } from "react-router-dom";
import styled, { keyframes, StyledComponent } from "styled-components/macro";
import classNames from "classnames";
import {
  THEME_COLOR,
  THEME_COLOR_DARK,
  ERROR_COLOR,
  ERROR_COLOR_DARK,
} from "../vars";
import { desaturate, lighten } from "polished";
import { Icon } from "./Icons";
import { Minus } from "./Icons";

const Button = styled(
  forwardRef<
    HTMLButtonElement,
    {
      type?: "button" | "submit";
      variant?: "primary" | "secondary" | "link" | "destroy" | "small";
      onClick?: (e: MouseEvent) => void | Promise<void>;
      children?: ReactNode;
      className?: string;
      isLoading?: boolean;
      setLoading?: (v: boolean) => void;
      //@todo remove these, used in skip links pages
      href?: string;
      to?: string;
    } & ButtonHTMLAttributes<HTMLButtonElement>
  >(function Button(
    {
      type = "button",
      variant = "primary",
      onClick: realOnClick = () => {},
      children,
      className,
      isLoading,
      setLoading,
      href,
      to,
      ...props
    },
    ref
  ) {
    const history = useHistory();
    const [handledIsLoading, handledSetLoading] = useState(false);

    if (isLoading === undefined) {
      isLoading = handledIsLoading;
      setLoading = handledSetLoading;
    }

    const onClick = async (e: MouseEvent) => {
      if (to) {
        history.push(to);
        return;
      }
      if (href) {
        window.location.href = href;
        return;
      }
      if (isLoading) return;
      if (setLoading) setLoading(true);
      try {
        await realOnClick(e);
      } finally {
        if (setLoading) setLoading(false);
      }
    };

    const Container: StyledComponent<"button", any> = {
      primary: ButtonContainer,
      secondary: SecondaryButtonContainer,
      link: LinkButtonContainer,
      destroy: DestroyButtonContainer,
      small: SmallerButtonContainer,
    }[variant];

    return (
      <Container
        type={type}
        {...props}
        onClick={onClick}
        className={classNames(className, {
          loading: isLoading,
        })}
        ref={ref}
        style={props.style}
      >
        <ButtonChildren>{children}</ButtonChildren>
        {isLoading && (
          <Loader>
            <div />
          </Loader>
        )}
      </Container>
    );
  })
)``;

export default Button;

const ButtonChildren = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition-duration: 0.2s;
`;

const loaderKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  display: inline-block;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > div {
    border-radius: 10000px;
    width: 1.5em;
    height: 1.5em;
    border: 2px solid transparent;
    border-left-color: white;
    animation: 1s ${loaderKeyframes} linear infinite;
  }
`;

const ButtonContainer = styled.button`
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  padding: 15px 30px;
  border-radius: 100px;
  transition: 0.25s;
  background-color: ${THEME_COLOR};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:active:not(.loading) {
    background-color: ${THEME_COLOR_DARK};
    transition: background-color 0s;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  &:focus {
    outline: none;
  }
  &.loading {
    pointer-events: none;
    background-color: ${lighten(0.25, desaturate(0.3, THEME_COLOR_DARK))};
    ${Loader} {
      opacity: 1;
    }
    ${ButtonChildren} {
      opacity: 0;
    }
  }
  & > svg {
    width: 18px;
    height: 18px;
    margin: 0 5px;
  }
  & ${Icon} {
    width: 13px;
    height: 13px;
    &:first-child:not(:last-child) {
      margin-right: 4px;
    }
    &:last-child:not(:first-child) {
      margin-left: 4px;
    }
  }
`;

const SmallerButtonContainer = styled(ButtonContainer)`
  padding: 10px 20px;
`;

const SecondaryButtonContainer = styled(ButtonContainer)`
  background-color: transparent;
  color: #676163;
  background-color: white;
  border: 1px solid #c3c4c7;
  &:hover:not(.loading) {
    background-color: transparent;
    color: #676163;
  }
  &:active:not(.loading) {
    background-color: rgba(0, 0, 0, 0.05);
    transition: background-color 0s;
    color: #676163;
  }
  &.loading {
    background-color: rgba(0, 0, 0, 0.01);
  }
  ${Loader} {
    & > div {
      border-left-color: ${THEME_COLOR};
    }
  }
`;

export const LinkButtonContainer = styled(ButtonContainer)`
  background-color: transparent;
  color: ${THEME_COLOR};
  padding: 8px 20px;
  text-transform: uppercase;
  font-weight: 600;
  &:hover:not(.loading) {
    background-color: transparent;
    color: rgb(22, 114, 167);
  }
  &:active:not(.loading) {
    background-color: transparent;
    transition: background-color 0s;
    color: ${THEME_COLOR_DARK};
    opacity: 0.5;
  }
  &.loading {
    background-color: transparent;
  }
  ${Loader} {
    & > div {
      border-left-color: ${THEME_COLOR};
    }
  }
`;

const DestroyButtonContainer = styled(LinkButtonContainer)`
  color: ${ERROR_COLOR};
  &:hover:not(.loading) {
    color: ${ERROR_COLOR};
  }
  &:active:not(.loading) {
    color: ${ERROR_COLOR};
    opacity: 0.5;
  }
  ${Loader} {
    & > div {
      border-left-color: ${ERROR_COLOR};
    }
  }
`;

const IconButtonContainer = styled.button`
  padding: 0;
  border: 0;
  margin: 0 4px;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  background: white;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1), 0 1.5px 3px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  color: #666;
  flex-grow: 0;
  flex-shrink: 0;
  svg {
    height: 22px;
    transform: scale(0.8);
  }
  &:active:not(.loading) {
    transform: scale(0.95);
    background: #fafafa;
  }
  &.loading {
    pointer-events: none;
    ${ButtonChildren} {
      opacity: 0;
    }
    ${Loader} {
      opacity: 1;
      & > div {
        border-left-color: ${THEME_COLOR};
      }
    }
  }
`;

const CloseButton = styled.button`
  border-radius: 1000px;
  border: 0;
  background: ${ERROR_COLOR};
  width: 14px;
  height: 14px;
  transform: translate(25%, -25%);
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  padding: 0;
  font-size: 8px;
  svg {
    width: 100%;
    height: 100%;
    transform: scale(0.7);
  }
  &:active {
    background: ${ERROR_COLOR_DARK};
  }
`;

const IconButtonBadge = styled.div`
  border-radius: 1000px;
  border: 0;
  background: ${THEME_COLOR};
  width: 14px;
  height: 14px;
  transform: translate(15%, -15%);
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  padding: 0;
  font-size: 10px;
  line-height: 14px;
`;

const IconButtonWrapper = styled.div`
  display: inline-flex;
  position: relative;
`;

export const IconButton = styled(
  forwardRef<
    HTMLButtonElement,
    {
      type?: "button" | "submit";
      variant?: "primary" | "secondary" | "link" | "destroy" | "small";
      onClick?: (e: MouseEvent) => void | Promise<void>;
      onRemove?: (e: MouseEvent) => void | Promise<void>;
      children?: ReactNode;
      className?: string;
      isLoading?: boolean;
      setLoading?: (v: boolean) => void;
      badge?: ReactNode;
    } & ButtonHTMLAttributes<HTMLButtonElement>
  >(function IconButton(
    {
      type = "button",
      onClick: realOnClick,
      children,
      className,
      isLoading,
      setLoading,
      onRemove,
      disabled,
      badge = null,
      ...props
    },
    ref
  ) {
    const [handledIsLoading, handledSetLoading] = useState(false);

    if (isLoading === undefined) {
      isLoading = handledIsLoading;
      setLoading = handledSetLoading;
    }

    const onClick = realOnClick
      ? async (e: MouseEvent) => {
          if (isLoading) return;
          if (setLoading) setLoading(true);
          try {
            await realOnClick(e);
          } finally {
            if (setLoading) setLoading(false);
          }
        }
      : undefined;

    return (
      <IconButtonWrapper>
        <IconButtonContainer
          type={type}
          {...props}
          onClick={onClick}
          className={classNames(className, {
            loading: isLoading,
          })}
          disabled={disabled || isLoading}
          ref={ref}
        >
          <ButtonChildren>{children}</ButtonChildren>
          {isLoading && (
            <Loader>
              <div />
            </Loader>
          )}
        </IconButtonContainer>
        {onRemove && (
          <CloseButton onClick={onRemove}>
            <Minus />
          </CloseButton>
        )}
        {badge && <IconButtonBadge>{badge}</IconButtonBadge>}
      </IconButtonWrapper>
    );
  })
)``;
