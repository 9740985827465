import { Fragment, Children, ReactNode } from "react";
import styled, { css } from "styled-components/macro";

const MARGIN = 4;

function Stack({
  children,
  space = 5,
  horizontal = false,
  center = false,
  grow = true,
}: {
  children: ReactNode;
  space?: number;
  horizontal?: boolean;
  center?: boolean;
  grow?: boolean;
}) {
  const childrenArr = Children.toArray(children);
  const Container = horizontal ? Horizontal : Fragment;
  return (
    <Container {...(horizontal ? { center, grow } : {})}>
      {childrenArr.map((child: ReactNode, index) => {
        return (
          <Fragment key={index}>
            {child}
            {index + 1 !== childrenArr.length && (
              <div
                style={{
                  [horizontal ? "width" : "height"]: MARGIN * space,
                  flexGrow: 0,
                  flexShrink: 0,
                }}
              />
            )}
          </Fragment>
        );
      })}
    </Container>
  );
}

export default Stack;

const Horizontal = styled.div<{ grow?: boolean; center?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  ${(p) =>
    p.grow &&
    css`
      & > * {
        flex-grow: 1;
        flex-shrink: 1;
      }
    `}
  ${(p) =>
    p.center &&
    css`
      align-items: center;
    `}
`;
