import { darken, lighten } from "polished";
export const NAV_HEIGHT = "60px";
export const NAV_WIDTH = "55px";
export const HEADER_HEIGHT = "50px";
export const GRID_GAP = "10px";
export const BORDER_RADIUS = "7px";
export const THEME_COLOR_ALT = "#a7f1d3";
export const THEME_COLOR_DARK = "#166481";
export const THEME_COLOR = lighten(0.1, THEME_COLOR_DARK);
export const THEME_COLOR_DARK_ALT = "#0a344a";
export const THEME_COLOR_GRAY = "#f5f5f7";
export const THEME_COLOR_DARK_GRAY = "#7f7f7f";
export const THEME_COLOR_LIGHT_GRAY = lighten(0.3, THEME_COLOR_DARK_GRAY);

export const SUCCESS_COLOR = "#32b52c";
export const SUCCESS_COLOR_DARK = darken(0.1, SUCCESS_COLOR);
export const SUCCESS_COLOR_LIGHT = "#72bf6f";
export const WARNING_COLOR = "#f6ab2f";
export const ERROR_COLOR = "#e6492d";
export const ERROR_COLOR_DARK = darken(0.1, ERROR_COLOR);

export const CONTROL_BORDER_COLOR = "#D8DCE6";
export const YELLOW = "#F9CB19";

export const CALENDLY_BLUE = "#006BFF";
