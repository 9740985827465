import styled, { css, keyframes } from "styled-components/macro";
import {
  useState,
  forwardRef,
  MouseEvent,
  Dispatch,
  SetStateAction,
  ReactNode,
  ButtonHTMLAttributes,
  InputHTMLAttributes,
} from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { Search as SearchIcon, Icon, SkipioLogo } from "./Icons";
import {
  THEME_COLOR,
  THEME_COLOR_GRAY,
  BORDER_RADIUS,
  HEADER_HEIGHT,
  THEME_COLOR_DARK,
  ERROR_COLOR,
} from "../vars";

type BoxProps = {
  elevated?: boolean;
  borderless?: boolean;
  error?: boolean;
  shaded?: boolean;
};

export const Box = styled.div<BoxProps>`
  border: 0;
  padding: 0;
  margin: 0;
  appearance: none;
  background: white;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: currentColor;
  text-decoration: none;
  border-radius: ${BORDER_RADIUS};
  ${(p) =>
    p.elevated
      ? css`
          box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1),
            0 1.5px 3px rgba(0, 0, 0, 0.1);
        `
      : css`
          border: 1px solid #d7d7d9;
        `};
  ${(p) =>
    p.borderless &&
    css`
      box-shadow: none;
      border: 0;
    `}
  &:link {
    border: 0;
    transition-duration: 0.2s;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1), 0 1.5px 3px rgba(0, 0, 0, 0.1);
    &:hover {
      box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.2);
    }
    &:active {
      transition-duration: 0s;
      transform: translateY(3px);
      box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1), 0 1.5px 3px rgba(0, 0, 0, 0.1);
    }
  }
  ${(p) =>
    p.error &&
    css`
      box-shadow: 0 0 0px 1px ${ERROR_COLOR}, 0px 0px 0px 2px ${ERROR_COLOR}33;
      ${p.shaded &&
      css`
        box-shadow: 0 0 0px 1px ${ERROR_COLOR}08 inset;
        border: 1px solid ${ERROR_COLOR}44;
        background-color: ${ERROR_COLOR}18;
        color: ${ERROR_COLOR};
      `}
    `}
`;

type BoxNavLinkProps = {
  badgePriority?: "high" | "normal" | "low";
};

export const BoxNavLink = styled(NavLink).attrs({
  activeClassName: "active",
})<BoxNavLinkProps>`
  display: inline-flex;
  height: ${HEADER_HEIGHT};
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #9598a6;
  padding: 0px 20px;
  transition-duration: 0.2s;
  font-weight: 500;
  &:not(:first-child) {
    margin-left: 1px;
  }
  body:not(.touch) &:hover {
    color: ${THEME_COLOR};
  }
  &.active {
    color: ${THEME_COLOR};
  }
  &[data-badge]:after {
    content: attr(data-badge);
    display: inline-block;
    left: 5px;
    top: -10px;
    font-size: 10px;
    border-radius: 100px;
    position: relative;
    text-align: center;
    min-width: 1em;
    padding: 0 0.6em;
    line-height: 1rem;
    ${(p) => {
      if (p.badgePriority === "high") {
        return css`
          color: white;
          background: ${ERROR_COLOR};
        `;
      } else if (p.badgePriority === "normal") {
        return css`
          color: white;
          background: ${THEME_COLOR};
        `;
      } else {
        return css`
          color: black;
          background: ${THEME_COLOR_GRAY};
          box-shadow: 0px 0px 0px 1px #0002, 0px 1px 2px #0001;
        `;
      }
    }}
  }
`;

const BoxNavSearchContainer = styled.div`
  display: inline-flex;
  height: calc(100% - 14px);
  width: calc(100% - 12px);
  margin: 6px 6px;
  border-radius: ${BORDER_RADIUS};
  background: #f5f5f5;
  border: 1px solid #d7d7d9;
  max-width: 100%;
  align-items: center;
  justify-content: flex-end;
  flex: auto;
  & > input {
    width: 100%;
    height: 100%;
    padding-left: 5px;
    padding-right: 10px;
    border: none;
    font-size: 13px;
    font-weight: 400;
    background: transparent;
    max-width: calc(100% - 20px);
  }
  & > ${SearchIcon} {
    pointer-events: none;
    margin-left: 10px;
    position: relative;
    z-index: 1;
    transform: scale(0.8);
  }
  &:focus-within {
    border-color: ${THEME_COLOR};
    box-shadow: 0px 0px 0px 2px ${THEME_COLOR}33;
    background: white;
  }
`;

type BoxHeaderFooterProps = {
  stacked?: boolean;
  white?: boolean;
};

export const BoxHeader = styled.div<BoxHeaderFooterProps>`
  background: ${THEME_COLOR_GRAY};
  position: relative;
  align-items: stretch;
  min-height: ${HEADER_HEIGHT};
  display: flex;
  box-shadow: 0px -1px 0 0 #d7d7d9 inset;
  &:last-child {
    box-shadow: none;
  }
  & > * {
    flex-grow: 1;
  }
  ${BoxNavSearchContainer} {
    background: white;
  }
  ${BoxNavLink} {
    box-shadow: 0px -1px 0 0 transparent inset;
    &.active {
      box-shadow: 0px -2px 0 0 ${THEME_COLOR} inset;
    }
  }
  ${(p) =>
    p.stacked
      ? css`
          box-shadow: none;
        `
      : css``};
  ${(p) =>
    p.white
      ? css`
          background: transparent;
          ${BoxNavSearchContainer}:not(:focus-within) {
            background: ${THEME_COLOR_GRAY};
          }
        `
      : css``}

  @media(max-width: 800px) {
    overflow-x: auto;
  }
`;

export const BoxFooter = styled.div<BoxHeaderFooterProps>`
  border-bottom-left-radius: ${BORDER_RADIUS};
  border-bottom-right-radius: ${BORDER_RADIUS};
  background: white;
  position: relative;
  display: flex;
  align-items: stretch;
  padding: 10px 20px;
  display: flex;
  background: ${THEME_COLOR_GRAY};
  box-shadow: 0px 1px 0 0 #d7d7d9 inset;
  flex-shrink: 0;
  & > * {
    flex-grow: 1;
  }
  ${BoxNavLink} {
    border-top: 2px solid transparent;
    line-height: calc(${HEADER_HEIGHT} - 6px);
    &.active {
      border-color: ${THEME_COLOR};
    }
  }
  ${(p) =>
    p.stacked
      ? css`
          box-shadow: none;
          padding-top: 0;
        `
      : css``};
  ${(p) =>
    p.white
      ? css`
          background: transparent;
        `
      : css``}
`;

export const BoxTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  position: relative;
  flex: auto;
  flex-grow: 0;
  font-size: 15px;
  color: #1c1d21;
  font-weight: 500;
  text-align: left;
  &:first-child {
    padding-left: 20px;
  }
`;

export const BoxNav = styled.nav`
  color: #1c1d21;
  font-weight: 400;
  height: ${HEADER_HEIGHT};
  font-size: 13px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  flex: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const BoxNavLeft = styled(BoxNav)`
  justify-content: flex-start;
  padding-left: 0px;
`;

export const BoxNavRight = styled(BoxNav)`
  justify-content: flex-end;
  padding-right: 0px;
`;

const loaderKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  display: inline-block;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > div {
    border-radius: 10000px;
    width: 1.5em;
    height: 1.5em;
    border: 2px solid transparent;
    border-left-color: currentColor;
    animation: 1s ${loaderKeyframes} linear infinite;
  }
`;

const BoxButtonChildren = styled.span`
  transition-duration: 0.2s;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

type BoxNavButtonProps = {
  badgePriority?: "high" | "normal" | "low";
};

export const BoxNavButtonContainer = styled.button<BoxNavButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 16px);
  margin: 8px;
  border-radius: 3px;
  font-weight: 500;
  font-family: inherit;
  box-shadow: 0px 0px 0px 1px #0002, 0px 1px 2px #0001;
  font-size: 13px;
  background: white;
  text-decoration: none;
  color: #474344;
  transition-duration: 0.1s;
  border: 0;
  text-align: center;
  min-width: ${HEADER_HEIGHT};
  padding: 0 12px;
  position: relative;
  &:not(.disabled) {
    &:hover,
    &:focus,
    &.active {
      background: rgba(0, 0, 0, 0.02);
    }
    &:active {
      box-shadow: 0px 0px 0px 1px #0002 inset;
      background: rgba(0, 0, 0, 0.05);
      transform: translateY(1px);
    }
  }
  ${Icon} {
    width: 20px;
    position: relative;
    &:first-child:not(:last-child) {
      margin-right: 10px;
    }
    &:last-child:not(:first-child) {
      margin-left: 10px;
    }
  }
  &[data-badge]:after {
    content: attr(data-badge);
    display: inline-block;
    right: -5px;
    top: -5px;
    font-size: 10px;
    border-radius: 100px;
    position: absolute;
    text-align: center;
    min-width: 1em;
    padding: 0 0.6em;
    line-height: 1rem;
    ${(p) => {
      if (p.badgePriority === "high") {
        return css`
          color: white;
          background: ${ERROR_COLOR};
        `;
      } else if (p.badgePriority === "normal") {
        return css`
          color: white;
          background: ${THEME_COLOR};
        `;
      } else {
        return css`
          color: black;
          background: ${THEME_COLOR_GRAY};
          box-shadow: 0px 0px 0px 1px #0002, 0px 1px 2px #0001;
        `;
      }
    }}
  }
  &.disabled {
    opacity: 0.5;
  }
  &.loading {
    pointer-events: none;
    ${Loader} {
      opacity: 1;
    }
    ${BoxButtonChildren} {
      opacity: 0;
    }
  }
  &.primary {
    color: ${THEME_COLOR_DARK};
    font-weight: 500;
  }
`;

export const BoxNavButton = styled(
  forwardRef<
    HTMLButtonElement,
    {
      isLoading?: boolean;
      primary?: boolean;
      setLoading?: Dispatch<SetStateAction<boolean>>;
      onClick: (e: MouseEvent) => void | Promise<void>;
      className?: string;
      children: ReactNode;
      active?: boolean;
      disabled?: boolean;
      badgePriority?: "high" | "normal" | "low";
    } & ButtonHTMLAttributes<HTMLButtonElement>
  >(
    (
      {
        isLoading,
        primary,
        setLoading,
        onClick: realOnClick = () => {},
        className,
        children,
        active,
        /*
        https://github.com/facebook/react/issues/4251
        
        There is a cross browser issue where onMouseOut
        does not fire on disabled elements, which means
        tooltips will appear but not disappear

        We want the tooltip to appear on disabled.
        */
        disabled,
        ...props
      },
      ref
    ) => {
      const [handledIsLoading, handledSetLoading] = useState(false);

      if (isLoading === undefined) {
        isLoading = handledIsLoading;
        setLoading = handledSetLoading;
      }

      const onClick = async (e: MouseEvent) => {
        if (disabled || isLoading) return;
        setLoading!(true);
        try {
          await realOnClick(e);
        } finally {
          setLoading!(false);
        }
      };

      return (
        <BoxNavButtonContainer
          {...props}
          ref={ref}
          onClick={onClick}
          className={classNames(className, {
            loading: isLoading,
            primary,
            disabled,
            active,
          })}
          aria-disabled={disabled ? "true" : undefined}
        >
          {isLoading && (
            <Loader>
              <div />
            </Loader>
          )}
          <BoxButtonChildren>{children}</BoxButtonChildren>
        </BoxNavButtonContainer>
      );
    }
  )
)``;

export const BoxNavSearch = styled(
  ({ style, className, ...props }: InputHTMLAttributes<HTMLInputElement>) => (
    <BoxNavSearchContainer style={style} className={className}>
      <SearchIcon />
      <input placeholder="Search..." {...props} />
    </BoxNavSearchContainer>
  )
)``;

type BoxContentProps = {
  shrink?: boolean;
  dark?: boolean;
};

export const BoxContent = styled.div<BoxContentProps>`
  padding: 20px;
  height: 100%;
  flex: auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  text-align: left;
  position: relative;
  ${(p) =>
    p.shrink &&
    css`
      flex: 0;
      height: auto;
    `};
  ${(p) =>
    p.dark &&
    css`
      background: ${THEME_COLOR_GRAY};
    `}
`;

export const BoxConnector = styled.div`
  position: relative;
  height: 20px;
  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    bottom: 0;
    background: #d7d7d9;
    transform: translateX(-50%);
  }
`;

const loadingAnim = keyframes`
 from {
   opacity: 0;
 }
 to {
   opacity: 1;
 }
`;

const LoadingBoxContentContainer = styled(BoxContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${SkipioLogo} {
    width: 100px;
    height: 100px;
    color: #dcdcdc;
    margin-bottom: 100px;
    animation: ${loadingAnim} 1s infinite alternate;
    animation-delay: 1s;
    animation-fill-mode: both;
  }
`;

export function LoadingBoxContent() {
  return (
    <LoadingBoxContentContainer>
      <SkipioLogo />
    </LoadingBoxContentContainer>
  );
}
