import { createContext, ReactChild, useContext } from "react";

const context = createContext(false);

export function IsReadOnlyBoundary({
  readOnly,
  children,
}: {
  readOnly?: boolean;
  children: ReactChild;
}) {
  return (
    <context.Provider value={Boolean(readOnly)}>{children}</context.Provider>
  );
}

export default function useIsReadonly() {
  return useContext(context) || false;
}
