import ReactDOM from "react-dom";
import Web2Txt from "./components/Web2Txt";

function mount(el: Element) {
  const id = el.getAttribute("data-skipio-web2txt-id")!;

  ReactDOM.render(<Web2Txt formId={id} />, el);
}

Array.from(document.querySelectorAll(`[data-skipio-web2txt-id]`)).forEach(
  mount
);

document.addEventListener(
  "keydown",
  (e) => {
    if (e.key === "Tab" && (e.target as Element).nodeName !== "INPUT") {
      document.body.classList.add("accessibility");
    } else if (e.key !== " " && e.key !== "Enter" && e.key !== "Escape") {
      document.body.classList.remove("accessibility");
    }
  },
  true
);
