import styled, { css } from "styled-components/macro";

const Title = styled.h2<{ center?: boolean; color?: string }>`
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  small {
    display: block;
    font-size: 13px;
    color: #676767;
    margin-top: 8px;
    font-weight: normal;
    line-height: 1.5em;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  ${(p) =>
    p.center &&
    css`
      text-align: center;
    `}
  ${(p) =>
    p.color &&
    css`
      color: ${p.color};
    `}
`;

export default Title;

export const BigTitle = styled(Title)`
  font-size: 36px;
  line-height: 36px;
  font-weight: 300;
  color: #0a344a;
`;

export const MediumTitle = styled(Title)`
  font-size: 24px;
  line-height: 24px;
  font-weight: 300;
  color: #0a344a;
`;

export const SubTitle = styled(Title)`
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  color: #0a344a;
`;
