import {
  useRef,
  useLayoutEffect,
  useState,
  forwardRef,
  MouseEvent,
  KeyboardEvent,
  ReactNode,
  Dispatch,
  SetStateAction,
  InputHTMLAttributes,
} from "react";
import styled, { keyframes } from "styled-components/macro";
import classNames from "classnames";
import { THEME_COLOR } from "../vars";
import useIsReadonly from "../hooks/use-is-readonly";

const Label = styled.label`
  display: block;
  line-height: 20px;
  margin: 0.2rem 0;
  padding: 1px;
  padding-left: 30px;
  position: relative;
  &.disabled {
    opacity: 0.5;
  }
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
`;

const Box = styled.div`
  position: absolute;
  left: 0px;
  margin-right: 15px;
  background-color: white;
  border: 1px solid #c3c4c7;
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  transition: border-color 0.2s ease-out;
  transform: translateY(-1px);
  vertical-align: top;
  body:not(.touch) ${Label}:not(.disabled):hover &,
  ${Label}:focus & {
    border-color: ${THEME_COLOR};
    cursor: pointer;
  }
`;

const Input = styled.input`
  display: none;
`;

const Mock = styled.span`
  background: transparent;
  border-color: #0a344a;
  border-style: solid;
  border-width: 0 0 2px 2px;
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate3d(0, 0, 1, -45deg);
  width: 10px;
  ${Input}:checked + & {
    opacity: 1;
    transition: opacity 0.2s ease-out;
  }
  &.indeterminate {
    transition: opacity 0.2s ease-out;
    opacity: 1;
    border-style: dotted;
  }
`;

const Spinner = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #888;
  text-transform: uppercase;
  font-weight: 700;
`;

const loaderKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoaderCircle = styled.div`
  display: block;
  border-radius: 10000px;
  height: 20px;
  width: 20px;
  margin-bottom: 20px;
  border: 2px solid transparent;
  border-left-color: ${THEME_COLOR};
  animation: 1s ${loaderKeyframes} linear infinite;
`;

const Checkbox = styled(
  forwardRef<
    HTMLLabelElement,
    {
      children?: ReactNode;
      onKeyPress?: (e: KeyboardEvent) => void;
      onClick?: (e: MouseEvent) => void;
      className?: string;
      value?: boolean;
      onChange: Dispatch<SetStateAction<boolean>>;
      indeterminate?: boolean;
      disabled?: boolean;
    } & Omit<
      InputHTMLAttributes<HTMLInputElement>,
      "onChange" | "onClick" | "value"
    >
  >(function Checkbox(
    {
      children,
      onKeyPress: rawOnKeyPress,
      onClick,
      className,
      value: checked,
      onChange: givenOnChange,
      indeterminate,
      disabled,
      ...props
    },
    ref
  ) {
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    disabled = useIsReadonly() || disabled;

    useLayoutEffect(() => {
      if (indeterminate !== undefined && inputRef.current)
        inputRef.current.indeterminate = indeterminate;
    }, [indeterminate]);

    const onKeyPress = (e: KeyboardEvent) => {
      const target = e.target as HTMLElement;
      if (target.tagName === "A") return; // Ignore if contains anchor
      if (rawOnKeyPress) rawOnKeyPress(e);
      if (e.key !== " ") return;
      e.preventDefault();
      const realCheckbox = target.querySelector("input")!;
      realCheckbox.click();
    };

    const onChange = async (value: boolean) => {
      setIsLoading(true);
      try {
        await givenOnChange(value);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Label
        tabIndex={0}
        onKeyPress={onKeyPress}
        className={classNames(className, { disabled })}
        onClick={onClick}
        ref={ref}
      >
        {isLoading ? (
          <Spinner>
            <LoaderCircle />
          </Spinner>
        ) : (
          <Box className={classNames({ checked })}>
            <Input
              ref={inputRef}
              type="checkbox"
              checked={checked}
              onChange={() => onChange(!checked)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              disabled={disabled}
              {...props}
            />
            <Mock className={classNames({ indeterminate })} />
          </Box>
        )}
        {children}
      </Label>
    );
  })
)``;

export default Checkbox;
