import { Dispatch, SetStateAction, useState } from "react";

type NewValue<T> = SetStateAction<T>;
export type OnChange<T> = Dispatch<NewValue<T>>;
export type OptionalStateProps<ValueType> = {
  value?: ValueType;
  onChange?: OnChange<ValueType>;
  initialValue?: ValueType;
};

export function useOptionalState<ValueType>({
  initialValue,
  value: givenValue,
  onChange: givenOnChange,
}: OptionalStateProps<ValueType>): [ValueType, OnChange<ValueType>] {
  const [value, onChange] = useState<ValueType>(initialValue!);
  if (initialValue) return [value, onChange];
  else return [givenValue!, givenOnChange!];
}
