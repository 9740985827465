import {
  useState,
  forwardRef,
  ReactNode,
  HTMLAttributes,
  InputHTMLAttributes,
} from "react";
import styled, { css, CSSProperties } from "styled-components/macro";
import classNames from "classnames";
import InputMask from "react-input-mask";
import TextareaAutosize from "react-autosize-textarea";
import {
  ERROR_COLOR,
  THEME_COLOR,
  SUCCESS_COLOR,
  THEME_COLOR_DARK_GRAY,
} from "../vars";
import Stack from "./Stack";
import { Info } from "./Icons";
import useIsReadonly from "../hooks/use-is-readonly";

let unique = 0;

const Container = styled.div``;

const MIN_HEIGHT = "40px";

export const stripeInputCss = {
  ".Input": {
    color: "black",
    fontSize: "14px",
    backgroundColor: "#fafafa",
    lineHeight: "1.8em",
    padding: "8px 16px",
    marginBottom: "9.5px",
    border: "1px solid #d7d7d9",
    borderRadius: "6px",
    boxShadow: "none",
  },
  ".Input:focus": {
    borderColor: `${THEME_COLOR}`,
    boxShadow: `0px 0px 0px 2px ${THEME_COLOR}33`,
    backgroundColor: "#fff",
  },
  ".Label": {
    color: "#0a344a",
    fontWeight: "500",
    fontSize: "13px",
    marginBottom: "10px",
    textTransform: "capitalize",
  },
};

export const inputCss = css<{ select?: boolean; textarea?: boolean }>`
  display: inline-block;
  width: 100%;
  min-height: ${MIN_HEIGHT};
  padding: 8px 16px;
  font-size: 14px;
  line-height: 1.8em;
  color: black;
  background-color: ${"#fafafa"};
  border: 1px solid #d7d7d9;
  border-radius: 6px;
  box-shadow: none;
  resize: none;
  ${(p) =>
    p.select &&
    css`
      height: ${MIN_HEIGHT};
      padding: 8px 4px;
    `}
  ${(p) =>
    p.textarea &&
    css`
      min-height: 40px;
    `}
  &:focus,&:focus-within,&.focused {
    border-color: ${THEME_COLOR};
    box-shadow: 0px 0px 0px 2px ${THEME_COLOR}33;
    background-color: #fff;
  }
  &:disabled {
    background-color: #f8f8f8;
    opacity: 0.8;
  }
  &.error {
    border-color: ${ERROR_COLOR};
    &:focus,
    &:focus-within {
      background: white;
      box-shadow: 0px 0px 0px 2px ${ERROR_COLOR}33;
    }
  }
  &.success {
    border-color: ${SUCCESS_COLOR};
    &:focus,
    &:focus-within {
      background: white;
      box-shadow: 0px 0px 0px 2px ${SUCCESS_COLOR}33;
    }
  }
  ::placeholder {
    opacity: 0.4;
  }
`;

const InputContainer = styled.input`
  ${inputCss}
`;

const MaskedInputContainer = styled(
  forwardRef<HTMLInputElement>((props, ref) => (
    <InputMask mask="" {...props} inputRef={ref} />
  ))
)`
  ${inputCss}
`;

export const Label = styled.label<{ underline?: boolean }>`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 10px;
  align-items: top;
  color: #0a344a;
  text-align: left;
  small {
    display: block;
    font-weight: 300;
    text-transform: none;
    margin-top: 4px;
    color: #676767;
  }
  ${(p) =>
    p.underline
      ? css`
          border-bottom: 1px solid ${THEME_COLOR_DARK_GRAY};
          padding-bottom: 5px;
        `
      : css``};
`;

const HelpTextContainer = styled.div`
  color: grey;
  font-size: 12px;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  line-height: 1.5em;
  ${Info} {
    height: 12px;
    width: 12px;
    transform: translateY(2px);
    flex-shrink: 0;
    margin-right: 4px;
  }
`;

export function HelpText({
  noIcon = false,
  children,
  ...props
}: {
  noIcon?: boolean;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <HelpTextContainer {...props}>
      {!noIcon && <Info />}
      <div>{children}</div>
    </HelpTextContainer>
  );
}

export const ErrorMessage = styled.span`
  color: ${ERROR_COLOR};
`;

export const SuccessMessage = styled.span`
  color: ${SUCCESS_COLOR};
`;

const RequiredDot = styled.div`
  color: ${ERROR_COLOR};
  display: inline-flex;
  margin: 0 3px;
`;

const Input = styled(
  forwardRef<
    HTMLInputElement & HTMLTextAreaElement & HTMLSelectElement,
    {
      label: ReactNode;
      sublabel?: string;
      type?: "textarea" | "select" | "number";
      id?: string;
      className?: string;
      value?: string | number | null;
      style?: CSSProperties;
      error?: string | string[];
      success?: string;
      focused?: boolean;
      required?: boolean;
      helpText?: string;
      disabled?: boolean;
      mask?: string;
      onChange: (v: any) => void;
      rows?: number;
    } & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "value">
  >(
    (
      {
        label,
        sublabel,
        type,
        id: givenId,
        className,
        value,
        style,
        error,
        success,
        focused,
        required = false,
        helpText,
        disabled,
        mask,
        onChange,
        rows,
        ...props
      },
      ref
    ) => {
      const [id] = useState(() => givenId || `input-${unique++}`);

      disabled = useIsReadonly() || disabled;

      return (
        <Container className={className} style={style}>
          {Boolean(label) && (
            <Label htmlFor={id}>
              <Stack space={1}>
                <div style={{ display: "inline" }}>
                  {label}
                  {Boolean(required) && <RequiredDot>*</RequiredDot>}
                  {Boolean(error) && (
                    <ErrorMessage>
                      &nbsp;{Array.isArray(error) ? error.join(", ") : error}
                    </ErrorMessage>
                  )}
                  {Boolean(success) && (
                    <SuccessMessage>&nbsp;{success}</SuccessMessage>
                  )}
                </div>
              </Stack>
              {Boolean(sublabel) && <small>{sublabel}</small>}
            </Label>
          )}
          {/* @TODO make this element type-safe */}
          {/* @ts-expect-error */}
          <InputContainer
            {...props}
            className={classNames(className, {
              error,
              success,
              focused,
            })}
            textarea={type === "textarea"}
            select={type === "select"}
            id={id}
            value={value === null ? "" : value}
            ref={ref}
            type={type}
            disabled={disabled}
            mask={mask}
            as={
              type === "textarea"
                ? rows === undefined
                  ? TextareaAutosize
                  : "textarea"
                : type === "select"
                ? "select"
                : mask
                ? MaskedInputContainer
                : InputContainer
            }
            required={required}
            onChange={(e) => onChange(e.target.value)}
            rows={rows}
          />
          {Boolean(helpText) && <HelpText>{helpText}</HelpText>}
        </Container>
      );
    }
  )
)``;

export default Input;
