/*
To configure the split to run on a specific api domain, include this
in your html.

https://immutablewebapps.org/

<script>
env = {
  API_HOST: window.location.origin,
  SSE_HOST: "https://sse-production.skipio.com/notifications"
}
</script>
*/

window.env = window.env || {};

const tryLocalStorage = (key) => {
  try {
    // some browser settings fail on trying to access localStorage
    // from an iframe.
    return localStorage[key];
  } catch (_) {
    return undefined;
  }
};

export const USE_MOCK = Boolean(
  window.env.USE_MOCKS ||
    process.env.REACT_APP_USE_MOCK ||
    tryLocalStorage("USE_MOCK")
);

export const USE_MOCK_SEED = Boolean(
  window.env.USE_MOCK_SEED ||
    process.env.REACT_APP_USE_MOCK_SEED ||
    tryLocalStorage("USE_MOCK_SEED")
);

export const API_HOST =
  tryLocalStorage("API_HOST") ||
  window.env.API_HOST ||
  process.env.REACT_APP_API_HOST;

export const SSE_HOST =
  tryLocalStorage("SSE_HOST") ||
  window.env.SSE_HOST ||
  process.env.REACT_APP_SSE_HOST;

export const INTEGRATIONS_HOST =
  tryLocalStorage("INTEGRATIONS_HOST") ||
  window.env.INTEGRATIONS_HOST ||
  process.env.REACT_APP_INTEGRATIONS_HOST ||
  "https://integrations.skipio.com";

export const FILESTACK_KEY = "Avh5A46e4SLmCexXm0Miwz";
export const METABASE_BOUNCE_REPORT_CAMPAIGN_QUESTION = 74;
export const NETLIFY_APP_URL = "https://beta.skipio.com";
export const STRIPE_PUBLIC_KEY =
  window.env.STRIPE_PUBLIC_KEY || process.env.REACT_APP_STRIPE_PK;
export const RECAPTCHA_SITE_KEY =
  window.env.RECAPTCHA_SITE_KEY || process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const MK_AGENCY_ID = "351417aa-2117-4953-8acf-7f4d84b62837";

export const GOOGLE_MAPS_API_KEY =
  tryLocalStorage("GOOGLE_MAPS_API_KEY") ||
  window.env.GOOGLE_MAPS_API_KEY ||
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
